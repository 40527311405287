import React, { useContext } from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

import Typography from "@material-ui/core/Typography"

import makeStyles from "@material-ui/core/styles/makeStyles"

import LangContext from "../../contexts/LangContext"
import { localizedSlugPrefix } from "../../gatsby/nodeHelpers"

import getTeamMemberLocations from "../../services/getTeamMemberLocations"

const useStyles = makeStyles(() => ({
  author: {
    fontWeight: 400,
    marginBottom: 20,
    fontSize: 26,
  },
}))

const Author = ({ teamMember }) => {
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const locations = getTeamMemberLocations(teamMember.uid)

  const renderLocations = () => {
    if (locations?.length > 1) {
      const cities = locations.map(l => l.data.city.text)
      return `${i18n.translations.authorAt} MONZA ARES ${cities.join(", ")}`
    }
    if (locations?.length > 0) {
      return `${i18n.translations.authorAt} MONZA ARES ${locations[0].data.city.text}`
    }

    return ""
  }

  const renderSpecialisation = () => {
    if (teamMember.data.specialisations.filter(({ specialisation }) => specialisation.document).length > 0) {
      return teamMember.data.specialisations[0].specialisation.document.data
        .title.text
    }

    return ""
  }

  return (
    <Typography variant="subtitle1" component="p">
      {`${i18n.translations.writtenBy} `}
      <Link to={`${localizedSlugPrefix(teamMember)}/echipa/${teamMember.uid}/`}>
        {teamMember.data.name.text}
      </Link>
      ,{` ${renderSpecialisation()} ${renderLocations()}`}
    </Typography>
  )
}

export default Author

Author.propTypes = {
  teamMember: PropTypes.object.isRequired,
}
